import Cookies from 'universal-cookie';

import { SET_USER, UNSET_USER, SET_HEADER_STATUS, SET_SETTINGS_DATA } from './types';

const cookies = new Cookies();

const getInitialState = () => ({
  user: typeof cookies.get('userdata') !== 'undefined' ? cookies.get('userdata') : false,
  adminHeaderStatus: typeof cookies.get('adminHeaderStatus') !== 'undefined' ? cookies.get('adminHeaderStatus') : '',
});

const reducer = (state = getInitialState(), action = {}) => {
  switch (action.type) {
    case SET_USER: {
      console.log(action.payload.user); // eslint-disable-line
      // eslint-disable-next-line max-len
      return Object.keys(action.payload.user).length ? { ...state, user: action.payload.user.data } : state;
    }
    case UNSET_USER: {
      return action.payload.user;
    }
    case SET_HEADER_STATUS: {
      return { ...state, headerStatus: action.payload.headerStatus };
    }
    case SET_SETTINGS_DATA: {
      return { ...state, settings: action.payload.settings };
    }
    default: return state;
  }
};

export default reducer;
