import {
  SET_ORDERS_DATA,
  SET_MORE_ORDERS_DATA,
  RESET_ORDERS_DATA,
  SET_ORDER_DETAILS_DATA,
  SET_RA_DATA,
  SET_EDIT_DATA,
  SET_RECIPIENT_DATA,
  SET_CARRIER_SERVICE_DATA,
  SET_ORDER_INFO_DATA,
  SET_RETURNED_ORDERS_DATA,
  SET_MORE_RETURNED_ORDERS_DATA,
  SET_FILTERS_DATA,
  SET_RECIPIENT_AUTOCOMPLETE_DATA,
  RESET_RECIPIENT_AUTOCOMPLETE_DATA,
  SET_VENDOR_ORDERS_DATA,
  SET_MORE_VENDOR_ORDERS_DATA,
  SET_IMPORT_INFO_DATA,
  SET_IMPORT_ORDERS_DATA,
  SET_SORT_COLUMN,
  SET_SORT_TYPE,
  SET_CLIENT_AUTOCOMPLETE_DATA,
  RESET_CLIENT_AUTOCOMPLETE_DATA,
  SET_ORDERS_AUTOCOMPLETE_DATA,
  RESET_ORDERS_AUTOCOMPLETE_DATA,
  SET_THIRD_PARTY_FORM_DATA,
  SET_THIRD_PARTY_TYPE,
  SET_ORDER_FILE_DATA,
  SET_CSV_ORDER_DATA,
  SET_IMPORT_CSV_ORDER_DATA,
} from './types';

const initialState = {
  list: {
    dataObj: {},
    dataArr: [],
  },
  vendorlist: {
    dataObj: {},
    dataArr: [],
  },
  returnedlist: {
    dataObj: {},
    dataArr: [],
  },
  detail: {},
  radata: {},
  dataentry: {},
  recipientdata: {},
  carrierdata: {},
  ordertypesdata: {},
  orderinfo: {},
  filters: [],
  autocompleteRecipient: {},
  importinfo: {},
  importorders: {},
  sortColumn: '',
  sortType: 'asc',
  autocompleteClient: [],
  autocompleteOrders: [],
  thirdpartyordertypesdata: {},
  thirdpartyordertypesdatanew: {},
  orderfile: {},
  csvorder: {},
  importcsvorder: {},
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_ORDERS_DATA: {
      return Object.assign({}, state, {
        list: {
          dataObj: action.payload.orders,
          dataArr: action.payload.orders.data,
        },
      });
    }
    case SET_MORE_ORDERS_DATA: {
      const dataArr = state.list.dataArr.concat(action.payload.orders.data);
      return Object.assign({}, state, {
        list: {
          dataObj: action.payload.orders,
          dataArr,
        },
      });
    }
    case SET_VENDOR_ORDERS_DATA: {
      return Object.assign({}, state, {
        vendorlist: {
          dataObj: action.payload.vendororders,
          dataArr: action.payload.vendororders.data,
        },
      });
    }
    case SET_MORE_VENDOR_ORDERS_DATA: {
      const dataArr = state.vendorlist.dataArr.concat(action.payload.vendororders.data);
      return Object.assign({}, state, {
        vendorlist: {
          dataObj: action.payload.vendororders,
          dataArr,
        },
      });
    }
    case RESET_ORDERS_DATA: {
      return Object.assign({}, state, {
        list: {
          dataObj: {},
          dataArr: [],
        },
      });
    }
    case SET_RETURNED_ORDERS_DATA: {
      return Object.assign({}, state, {
        returnedlist: {
          dataObj: action.payload.returnedorders,
          dataArr: action.payload.returnedorders.data,
        },
      });
    }
    case SET_MORE_RETURNED_ORDERS_DATA: {
      const dataArr = state.returnedlist.dataArr.concat(action.payload.returnedorders.data);
      return Object.assign({}, state, {
        returnedlist: {
          dataObj: action.payload.returnedorders,
          dataArr,
        },
      });
    }
    case SET_ORDER_DETAILS_DATA: {
      return { ...state, detail: action.payload.detail };
    }
    case SET_RA_DATA: {
      return { ...state, radata: action.payload.radata };
    }
    case SET_EDIT_DATA: {
      return { ...state, dataentry: action.payload.dataentry };
    }
    case SET_RECIPIENT_DATA: {
      return { ...state, recipientdata: action.payload.recipientdata };
    }
    case SET_CARRIER_SERVICE_DATA: {
      return {
        ...state,
        carrierdata: action.payload.carrierdata.carrier_service_levels,
        ordertypesdata: action.payload.carrierdata.order_types,
      };
    }
    case SET_THIRD_PARTY_FORM_DATA: {
      return {
        ...state,
        thirdpartyordertypesdata: action.payload.thirdpartformdata.thirdparty_return_order_types,
      };
    }
    case SET_THIRD_PARTY_TYPE: {
      const tptdata = state.thirdpartyordertypesdata;
      tptdata.push(action.payload.thirdpartytype.attributes);
      return {
        ...state,
        thirdpartyordertypesdata: tptdata,
        thirdpartyordertypesdatanew: action.payload.thirdpartytype.attributes,
      };
    }
    case SET_ORDER_INFO_DATA: {
      return { ...state, orderinfo: action.payload.orderinfo };
    }
    case SET_FILTERS_DATA: {
      return { ...state, filters: action.payload.filters };
    }
    case SET_RECIPIENT_AUTOCOMPLETE_DATA: {
      return { ...state, autocompleteRecipient: action.payload.autocompleteRecipient };
    }
    case RESET_RECIPIENT_AUTOCOMPLETE_DATA: {
      return { ...state, autocompleteRecipient: {} };
    }
    case SET_IMPORT_INFO_DATA: {
      return { ...state, importinfo: action.payload.importinfo };
    }
    case SET_IMPORT_ORDERS_DATA: {
      return { ...state, importorders: action.payload.importorders };
    }
    case SET_SORT_COLUMN: {
      return { ...state, sortColumn: action.payload.sortColumn };
    }
    case SET_SORT_TYPE: {
      return { ...state, sortType: action.payload.sortType };
    }
    case SET_CLIENT_AUTOCOMPLETE_DATA: {
      return { ...state, autocompleteClient: action.payload.autocompleteClient };
    }
    case RESET_CLIENT_AUTOCOMPLETE_DATA: {
      return { ...state, autocompleteClient: [] };
    }
    case SET_ORDERS_AUTOCOMPLETE_DATA: {
      return { ...state, autocompleteOrders: action.payload.autocompleteOrders };
    }
    case RESET_ORDERS_AUTOCOMPLETE_DATA: {
      return { ...state, autocompleteOrders: [] };
    }
    case SET_ORDER_FILE_DATA: {
      return { ...state, orderfile: action.payload.orderfile };
    }
    case SET_CSV_ORDER_DATA: {
      return { ...state, csvorder: action.payload.csvorder };
    }
    case SET_IMPORT_CSV_ORDER_DATA: {
      return { ...state, importcsvorder: action.payload.importcsvorder };
    }
    default: return state;
  }
};

export default reducer;
